<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-adjustment-96.png"
          class="h-20 ml-2 inline-block"
        />
        تنظیمات اصلی
      </h2>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Channel" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">
        {{ channel.cid }}
      </div>
    </template>
    <template #default>
      <!-- ANCHOR - sign -->
      <ChannelSettingCard @setSettings="submit('signs')" :submitKey="true">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-sign-up-96.png" class="inline-block h-20" />
          امضا
        </div>
        <div>
          <MnrCheckSlider v-model:checked="channel.live_sign">
            جایگذاری لحظه‌ای امضا کانال
          </MnrCheckSlider>
        </div>
        <div class="grid sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrTextarea v-model="channel.signature" title="امضای کانال" />
          <MnrTextarea
            v-if="channel.userChannel"
            v-model="channel.userChannel.signature"
            title="امضای شما در کانال"
          />
        </div>

        <template #help>
          <ul class="list-inside list-disc">
            <li class="py-1">
              <span class="font-bold">جایگذاری لحظه‌ای</span>
              تنها برای امضای کانال امکانپذیر است
            </li>
            <li class="py-1">
              <span class="font-bold">امضای کانال</span>
              را میتوانید برای جایگذاری لحظه ای استفاده کنید همچنین موقع ارسال پست از پنل
              میتوانید انتخاب کنید تا به زیر پست اضافه شود
            </li>
            <li class="py-1">
              <span class="font-bold">امضای شما</span>
              را میتوانید در هنگام ارسال پست از پنل انتخاب کنید تا در زیر پست قرار گیرد
            </li>
          </ul>
        </template>
      </ChannelSettingCard>

      <!-- ANCHOR - like keys -->
      <ChannelSettingCard @setSettings="submit('rate_keys')" :submitKey="true">
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-smiling-face-with-heart-96.png"
            class="inline-block h-20"
          />
          کلید های نظر سنجی
        </div>
        <div>
          <MnrCheckSlider v-model:checked="channel.live_keys">
            جایگذاری لحظه‌ای کلید ها
          </MnrCheckSlider>
        </div>
        <div
          v-for="(keyRow, index) in rateKey"
          :key="index"
          class="
            p-1
            sm:p-3
            border
            bg-blueGray-50
            border-blueGray-200
            mt-5
            rounded
            shadow-md
          "
        >
          <Button
            class="rounded-full h-6 text-sm w-auto mb-3 inline-block"
            color="red"
            @click="rateKey.splice(index, 1)"
          >
            × حذف سطر
          </Button>
          <div class="flex gap-1" dir="ltr">
            <div
              v-for="(keyCol, indexCol) in keyRow"
              :key="indexCol"
              dir="rtl"
              class="p-1 sm:p-2 w-full bg-blueGray-100 border border-blueGray-200 rounded"
            >
              <Button
                class="rounded-full h-5 w-5 mb-3 inline-block p-0"
                color="red"
                @click="
                  keyRow.splice(indexCol, 1);
                  if (keyRow.length < 1) rateKey.splice(index, 1);
                "
              >
                ×
              </Button>
              <MnrText v-model="keyCol.value" placeholder="اموجی یا کلمه" />
            </div>
            <div class="w-7 flex-shrink-0" v-show="keyRow.length < 4">
              <Button
                class="rounded-md h-full w-full"
                color="cyan"
                @click="keyRow.push({ value: '' })"
              >
                +
              </Button>
            </div>
          </div>
        </div>
        <Button
          class="inline-block w-auto mt-5"
          color="teal"
          @click="rateKey.push([{ value: '' }])"
        >
          اضافه کردن سطر
        </Button>

        <template #help>
          <p>
            این کلید ها زیر پست ها قرار میگیرد و کاربران با زدن آنها به آنها امتیاز
            می‌دهند. این کلید ها را با کلید های لینک دار اشتباه نگیرید
          </p>
        </template>
      </ChannelSettingCard>

      <!-- ANCHOR - ad time -->
      <ChannelSettingCard @setSettings="submit('ad_time')" :submitKey="true">
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-web-advertising-96.png"
            class="inline-block h-20"
          />
          ساعت ارسال تبلیغ
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrText v-model="channel.ad_time" type="time" />
        </div>

        <template #help>
          <p>
            اگر به طور رایگان ار ربات استفاده کنید ربات روزانه یک تبلیغ به کانال شما ارسال
            می‌کند که می‌توانید ساعت آنرا خودتان انمتخاب کنید. اگر نمیخواهید تبلیغات به
            کانال شما ارسال شود بایر ربات را خریداری کنید
          </p>
          <ul class="list-inside list-disc">
            <li class="py-1">
              تبلیغات به طور خودکار در کانال شما ارسال می‌شود و تا 15 دقیقه بعد از آن
              نباید پستی ارسال شود
            </li>
            <li class="py-1">
              اگر تا 15 دقیقه بعد از تبلیغات پستی ارسال شود ربات آن را حذف می‌کند
            </li>
            <li class="py-1">
              تبلیغات به طور خودکار توسط ربات حذف می‌شود اگر به صورت دستی آنرا حذف کنید
              اخطار دریافت میکنید و بعد از دو اخطار ربات غیر فعال می‌شود
            </li>
          </ul>
        </template>
      </ChannelSettingCard>

      <!-- ANCHOR - invite setting -->
      <ChannelSettingCard @setSettings="submit('join')" :submitKey="true">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-link-96.png" class="inline-block h-20" />
          لینک دعوت کاربران
        </div>
        <div
          v-if="!channel.pro_tools"
          class="
            p-3
            bg-red-100
            border-red-200
            text-red-900
            font-bold
            border
            rounded-md
            mb-5
            block
          "
        >
          <div class="flex items-center">
            <img src="@/assets/images/icons8-brawl-stars-96.png" />
            این قابلیت فقط برای اشتراک‌های آبی، برنزی، نقره‌ای و طلایی وجود دارد.
          </div>
        </div>
        <p class="py-3">
          با فعال سازی این قابلیت می‌توانید از کاربران بخواهید دوستانشان را به کانال دعوت
          کنند. این قابلیت فقط برای کانال هایی که اشتراک آبی، برنزی، نقره‌ای یا طلایی خرید
          کرده باشند قابل فعال سازی است. اطلاعات بیشتر را میتوانید در قسمت راهنما بخوانید.
          <a href="https://youtu.be/JB9DHlEAVUE" target="_blank" class="text-sky-500">
            یا ویدیو آموزشی را در یوتیوب مشاهده کنید.
          </a>
        </p>
        <div class="p-3 bg-blueGray-50 border border-blueGray-200 rounded-md" dir="ltr">
          link:
          <b class="text-sky-500">https://t.me/mnrcbot?start=link_{{ channel.cid }}</b>
        </div>

        <MnrCheckSlider v-model:checked="channel.joins">لینک دعوت کاربران</MnrCheckSlider>

        <template #help>
          <p>
            بعضی از کانال ها ممکنه از ممبر هاشون بخوان با دعوت دوستانشون به کانال جایزه ای
            برای کاربران در نظر بگیرند. مثلا کانال فروش پوشاک در ازای دعوت به کاربران
            تخفیف دهد و ...، تا امروز این برای کانال ها امکان پذیر نبود اما با به روز
            رسانی های اخیر تلگرام و به کمک این ربات شما قادر خواهید بود این کار را انجام
            دهید. میتوانید آموزش کامل و توضیحات بیشتر را در یوتیوب مشاهده کنید
          </p>
          <div class="text-center">
            <Button
              href="https://youtu.be/JB9DHlEAVUE"
              target="_blank"
              color="red"
              class="mt-5 inline-flex"
            >
              ویدیو آموزشی در یوتیوب
            </Button>
          </div>
        </template>
      </ChannelSettingCard>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrTextarea from "@/components/mnr/MnrTextarea.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";
import ChannelSettingCard from "@/components/ChannelSettingCard.vue";
import _ from "lodash";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrText,
    MnrTextarea,
    MnrCheckSlider,
    ChannelSettingCard,
  },
  data() {
    return {
      firstLoading: true,
      errors: [],
      channel: {},
      rateKey: [],
      list_pro: {
        silver: "نقره ای",
        golden: "طلایی",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.$axios
        .get("api/owner-channels/" + $this.$route.params.channelId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;

          // $this.rateKey = response.data.channel.keys_json;
          $this.rateKey = $this.rateKey ?? [];
          $this.rateKey = _.map(response.data.channel.keys_json, function (item) {
            return _.map(item, function (key) {
              return { value: key };
            });
          });
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
    submit(update, id = this.$route.params.channelId) {
      // console.log(update, id);
      // return;
      let $this = this;
      $this.sending = update;
      // console.log(id, update);
      $this.errors = [];
      $this.$axios
        .put("/api/owner-channels/" + $this.$route.params.channelId, {
          id: $this.$store.state.user.id,
          update: update,
          channelIds: id,
          channel: $this.channel,
          rateKeys: $this.rateKey,
          // groupsId: id,
          // group: $this.group,
          // noAd: $this.noAd,
        })
        .then(function (response) {
          // console.log(response);
          // $this.group = response.data.group;
          // $this.setting = response.data.setting;
          // $this.otherSetting = response.data.otherSetting;
          // $this.otherGroup = response.data.otherGroup;
          $this.$notify({ text: "تغییرات ذخیره شد", type: "success" });
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.$notify({ text: "خطا: تغییرات ذخیره نشد", type: "error" });
        })
        .then(function () {
          $this.sending = false;
        });
    },
  },
};
</script>
